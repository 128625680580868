<template>
  <div class="d-flex flex-column py-4">
    <div class="header-actions">
      <!-- <h2 class="view-title">Keyword Research History</h2> -->
      <v-btn color="primary" dark @click="newReport">
        <v-icon left> mdi-plus </v-icon>
        New Report
      </v-btn>
      <!-- <v-text-field label="Search" v-model="search" class="search" prepend-icon="mdi-magnify"></v-text-field> -->
    </div>

    <div class="header-top">
      <h2 class="view-title">Keyword Research History</h2>
      <v-text-field
        v-model="search.search"
        label="Search"
        class="search"
        prepend-icon="mdi-magnify"
        clearable
        @click:clear="clearSearch"
      ></v-text-field>
    </div>

    <div>
      <template>
        <v-data-table
          :key="dataTableKeyKeywords"
          :headers="headers"
          :items="reports"
          :items-per-page="10"
          item-key="id"
          sort-by="id"
          :sort-desc="sort_desc"
          class="elevation-1"
          :loading="loading"
          :options.sync="options"
          :server-items-length="total"
          loading-text="Loading... Please wait"
          mobile-breakpoint="0"
          :footer-props="{ 'items-per-page-options': items_per_page }"
        >
          <template v-slot:[`item.client_url`]="{ item }">
            <a :href="externalLink(item.client_url)" target="_blank" class="url-domain">{{ item.client_url }} </a>
          </template>

          <template v-slot:item.id="{ item }">
            <a @click="$router.push('/kw-research/report/' + item.id)">{{ item.id }}</a>
          </template>

          <template v-slot:item.competitor_urls="{ item }">
            <div v-if="item.type === 'paid'">
              <div class="competitor_urls" v-for="(url, i) in item.competitor_urls" :key="i">
                <a :href="externalLink(url)" target="_blank">{{ url }}</a>
              </div>
            </div>
            <div v-else-if="item.type === 'ranked_keywords'">
              <div class="competitor_urls" v-for="(competitor, i) in item.competitor_urls" :key="i">
                <a :href="externalLink(competitor.url)" target="_blank">{{ competitor.url }}</a>
              </div>
            </div>
          </template>

          <template v-slot:[`item.type`]="{ item }">
            <span v-if="item.type === 'paid'">{{ 'Paid' }} </span>
            <span v-if="item.type === 'organic'">{{ 'Organic' }} </span>
            <span v-if="item.type === 'ranked_keywords'">{{ 'Ranked Keywords' }} </span>
          </template>

          <template v-slot:[`item.created_at`]="{ item }">
            <span>{{ formatDate(item.created_at) }} </span>
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <div class="cont-actions">
              <v-btn
                v-if="item.status === 'completed'"
                color="primary"
                outlined
                @click="goToPath('/kw-research/report/' + item.id)"
              >
                Report
              </v-btn>
              <v-btn
                v-else-if="item.status === 'failed'"
                color="error"
                outlined
                @click="goToPath('/kw-research/await/' + item.id)"
              >
                Failed
              </v-btn>
              <v-btn v-else color="warning" outlined @click="goToPath('/kw-research/await/' + item.id)">
                Pending
              </v-btn>
              <v-btn color="error" icon @click="showDialog(item)">
                <v-icon> mdi-delete </v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </template>
    </div>
    <v-row justify="center">
      <v-col cols="12" md="6">
        <template>
          <v-row justify="center">
            <v-dialog v-model="dialog_delete" persistent max-width="500">
              <v-card>
                <v-card-title class="text-h5"> Are you sure to delete this report? </v-card-title>
                <v-card-text>
                  This process is irreversible, and can no longer be recovered, would you like to continue?
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="green darken-1" text outlined @click="closeDialog()"> Close </v-btn>
                  <v-btn color="error" text outlined @click="deleteReport()"> Delete </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import store from '@/store'

import { mapActions } from 'vuex'

// import { ref, watch } from '@vue/composition-api'
// import { onMounted } from 'vue-demi'
import moment from 'moment'

// import { useRouter } from '@core/utils'
import DialogDeleteReport from '@/components/dialog/DialogDelete.vue'

export default {
  components: {
    DialogDeleteReport,
  },
  data() {
    return {
      loading: false,
      headers: [
        {
          text: 'User',
          value: 'user.email',
        },
        {
          text: 'Report ID',
          align: 'start',
          value: 'id',
        },
        {
          text: 'Report Name',
          align: 'start',
          value: 'report_name',
        },
        { text: 'Client URL', value: 'client_url', width: 250 },
        { text: 'Compeitor URLs', value: 'competitor_urls' },
        { text: 'Type', value: 'type' },
        { text: 'Created At', value: 'created_at' },
        { text: 'Actions', value: 'status' },
      ],
      reports: [],
      total: 0,
      items: 10,
      sort_desc: true,
      loadingKey: true,
      dataTableKeyKeywords: 0,
      options: {},
      search: {
        search: '',
      },
      dialog_delete: false,
      item: null,
      items_per_page: [5, 10, 25, 50, 100],
    }
  },
  watch: {
    options: {
      handler() {
        this.getReportData()
      },
      deep: true,
    },
    search: {
      handler() {
        this.getReportData()
      },
      deep: true,
    },
  },
  mounted() {
    this.getReportData()
  },
  methods: {
    ...mapActions({
      history: 'keyword_research/history',
      delete: 'keyword_research/delete',
    }),
    async getReportData() {
      this.loading = true
      await this.history(
        new URLSearchParams({
          ...this.options,
          ...this.search,
        }).toString(),
      )
        .then(resp => {
          if (resp && resp.data) {
            this.data = resp.data
            this.reports = resp.data.reports
            this.total = resp.data.total
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    newReport() {
      const path = '/kw-research/new'
      this.$router.push({ path })
    },

    formatDate(date) {
      return moment(date).fromNow()
    },
    showDialog(item) {
      this.item = item
      this.dialog_delete = true
    },
    closeDialog() {
      this.dialog_delete = false
    },
    goToPath(path) {
      this.$router.push({ path })
    },
    externalLink(url) {
      if (url.includes('https://') || url.includes('http://')) {
        return url
      } else {
        return 'https://' + url
      }
    },
    async deleteReport() {
      this.loading = true
      this.dialog_delete = false
      await this.delete(this.item.id)
        .then(resp => {
          this.item = null
          this.getReportData()
        })
        .finally(() => {
          this.loading = false
        })
    },
    clearSearch() {
      this.search.search = ''
    },
  },
}
</script>

<style scoped>
.header-actions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  row-gap: 10px;
  margin-bottom: 20px;
}
.header-top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.v-data-table >>> td {
  min-width: 120px;
}
.url-domain {
  max-width: 300px !important;
  word-break: break-all !important;
  gap: 5px;
}
.competitor-urls {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
}

.view-title {
  margin-bottom: 20px;
}
.search {
  max-width: 350px;
}
.cont-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

@media (max-width: 992px) {
  .col-keyword {
    min-width: 200px;
  }
  .url-domain {
    min-width: 300px;
  }
}

@media (max-width: 767px) {
  .header-actions {
    justify-content: center;
    align-items: center;
  }
  .header-top {
    justify-content: center;
    align-items: center;
  }
  .view-title {
    text-align: center;
  }
}
</style>
