var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column py-4"},[_c('div',{staticClass:"header-actions"},[_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":_vm.newReport}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" New Report ")],1)],1),_c('div',{staticClass:"header-top"},[_c('h2',{staticClass:"view-title"},[_vm._v("Keyword Research History")]),_c('v-text-field',{staticClass:"search",attrs:{"label":"Search","prepend-icon":"mdi-magnify","clearable":""},on:{"click:clear":_vm.clearSearch},model:{value:(_vm.search.search),callback:function ($$v) {_vm.$set(_vm.search, "search", $$v)},expression:"search.search"}})],1),_c('div',[[_c('v-data-table',{key:_vm.dataTableKeyKeywords,staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.reports,"items-per-page":10,"item-key":"id","sort-by":"id","sort-desc":_vm.sort_desc,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.total,"loading-text":"Loading... Please wait","mobile-breakpoint":"0","footer-props":{ 'items-per-page-options': _vm.items_per_page }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.client_url",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"url-domain",attrs:{"href":_vm.externalLink(item.client_url),"target":"_blank"}},[_vm._v(_vm._s(item.client_url)+" ")])]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.$router.push('/kw-research/report/' + item.id)}}},[_vm._v(_vm._s(item.id))])]}},{key:"item.competitor_urls",fn:function(ref){
var item = ref.item;
return [(item.type === 'paid')?_c('div',_vm._l((item.competitor_urls),function(url,i){return _c('div',{key:i,staticClass:"competitor_urls"},[_c('a',{attrs:{"href":_vm.externalLink(url),"target":"_blank"}},[_vm._v(_vm._s(url))])])}),0):(item.type === 'ranked_keywords')?_c('div',_vm._l((item.competitor_urls),function(competitor,i){return _c('div',{key:i,staticClass:"competitor_urls"},[_c('a',{attrs:{"href":_vm.externalLink(competitor.url),"target":"_blank"}},[_vm._v(_vm._s(competitor.url))])])}),0):_vm._e()]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.type === 'paid')?_c('span',[_vm._v(_vm._s('Paid')+" ")]):_vm._e(),(item.type === 'organic')?_c('span',[_vm._v(_vm._s('Organic')+" ")]):_vm._e(),(item.type === 'ranked_keywords')?_c('span',[_vm._v(_vm._s('Ranked Keywords')+" ")]):_vm._e()]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.created_at))+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"cont-actions"},[(item.status === 'completed')?_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.goToPath('/kw-research/report/' + item.id)}}},[_vm._v(" Report ")]):(item.status === 'failed')?_c('v-btn',{attrs:{"color":"error","outlined":""},on:{"click":function($event){return _vm.goToPath('/kw-research/await/' + item.id)}}},[_vm._v(" Failed ")]):_c('v-btn',{attrs:{"color":"warning","outlined":""},on:{"click":function($event){return _vm.goToPath('/kw-research/await/' + item.id)}}},[_vm._v(" Pending ")]),_c('v-btn',{attrs:{"color":"error","icon":""},on:{"click":function($event){return _vm.showDialog(item)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)],1)]}}],null,true)})]],2),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[[_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.dialog_delete),callback:function ($$v) {_vm.dialog_delete=$$v},expression:"dialog_delete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Are you sure to delete this report? ")]),_c('v-card-text',[_vm._v(" This process is irreversible, and can no longer be recovered, would you like to continue? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":"","outlined":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"error","text":"","outlined":""},on:{"click":function($event){return _vm.deleteReport()}}},[_vm._v(" Delete ")])],1)],1)],1)],1)]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }